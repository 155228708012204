import { FormControlLabel, Checkbox } from "@mui/material";

import "./styledCheckbox.scss";

interface StyledCheckboxProps {
  label: string;
  value: string;
  checked?: boolean;
  disabled?: boolean;
  simple?: boolean;
  size?: 'small' | 'medium';
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}

export const StyledCheckbox = ({
  label,
  value,
  checked = false,
  disabled = false,
  simple = false,
  onChange,
  size = 'medium',
  ...props
}: StyledCheckboxProps) => {
  const mode = simple ? "SimpleStyledCheckbox" : size === "small" ? "StyledCheckbox small" : "StyledCheckbox";
  return (
    <FormControlLabel
      className={mode}
      label={label}
      value={value}
      checked={checked}
      disabled={disabled}
      {...props}
      control={<Checkbox className="checkbox-button" onChange={onChange} size={size} />}
    />
  );
};
