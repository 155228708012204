import { useCallback } from "react";
import { SimpleModal } from "../SimpleModal/SimpleModal";
import * as d3 from "d3";
import 'd3.chart';
import "./d3.charts.defaults.js";
import "./d3.basketball-shot-chart.js";
import "./d3.basketball-shot-chart.css";
import { Theme, useMediaQuery } from "@mui/material";
import { ShotChartData } from "../../../models/TableData";

interface Props {
  data: ShotChartData;
  isOpen: boolean;
  handleClose: (event: object, reason: string) => void;
  title?: string
}

export default function ShotChartDialog({ data, isOpen, handleClose, title }: Props) {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const drawChart = useCallback((svgRef: SVGSVGElement) => {
    if (svgRef === null) {
      return;
    }

    const chartData = JSON.parse(data.replace(/(['"])?([a-z0-9A-Z_]+)(['"])?:/g, '"$2": '));

    d3.select(svgRef)
      .chart("BasketballShotChart", {
      width: isMobile ? 300 : 770,
      title: '',
      hexagonBinVisibleThreshold: 0,
      heatScale: d3.scale.quantile()
        .domain([0.65, 1.35])
        .range(['#5458A2', '#6689BB', '#FADC97', '#F08460', '#B02B48']),
    }).draw(chartData)

  }, [data, isMobile])


  return (
    <SimpleModal isOpen={isOpen} handleClose={handleClose} maxWidth="md" title={title}>
      <svg ref={drawChart} />
    </SimpleModal>
  )
}
