import "./dataTable.scss"
import { memo, useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { TableData, TableRowData } from "../../../models/TableData";
import Cell from "./DataTableCell";

const DataTable = memo(({ data }: { data: TableData } ) => {
  const [sortColumn, setSortColumn] = useState<string>("Poss")
  const [sortAscending, setSortAscending] = useState<boolean>(false)
  const [sortedData, setSortedData] = useState<TableData>(data)

  const sort = (column: string) => {
    if (sortColumn === column) {
      setSortAscending(!sortAscending)
    } else {
      setSortColumn(column)
      setSortAscending(false)
    }
  }

  useEffect(() => {
    function sortComp(a: TableRowData, b: TableRowData) {
      const aa = (isNaN(parseFloat(a[sortColumn]))) ? a[sortColumn] : parseFloat(a[sortColumn]);
      const bb = (isNaN(parseFloat(b[sortColumn]))) ? b[sortColumn] : parseFloat(b[sortColumn]);
      if (aa === bb) return 0
      if (sortAscending) return aa < bb ? -1 : 1
      return aa < bb ? 1 : -1
    }
    setSortedData([...data].sort(sortComp))
  }, [sortColumn, sortAscending, data])

  if (data.length === 0) return null

  const columnHeaders = Object.keys(data[0])
  return (
    <TableContainer className='data-table-container'>
      <Table className='data-table' stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Rank</TableCell>
            {columnHeaders.map(header => <TableCell key={header} onClick={() => sort(header)}>{header}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.map((row, i) => (
            <TableRow key={`row:${i}`}>
              <TableCell>{i + 1}</TableCell>
              {columnHeaders.map(header => <Cell key={`row:${i}:${header}`} data={row[header]} header={header} />)}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
})

export default DataTable
