import { Add } from "@mui/icons-material";
import { Box, Typography, Button } from "@mui/material";
import { useExplorerStore } from "../../../data/ExplorerStore";
import DefaultQueryGroupFilters from "./DefaultQueryGroupFilters";
import DimensionSelect from "./DimensionSelect";

interface Props {
  metricGroupIndex: number;
}

export default function QueryForm({ metricGroupIndex }: Props) {
  const isQueryGroup = metricGroupIndex === 0;
  const metricGroup = useExplorerStore(
    ({ metricGroups }) => metricGroups[metricGroupIndex]
  );
  const isLoading = useExplorerStore(
    ({ filters }) => !filters
  );
  const addDimension = useExplorerStore(({ addDimension }) => addDimension);

  return (
    <>
      <Box mb={2}>
        <Typography mb={1} fontSize={18}>
          Breakdowns
        </Typography>
        {isLoading ? (
          <Typography>Loading filters...</Typography>
        ) : (
          <>
            {metricGroup.dimensions.map((d, i) => (
              <DimensionSelect
                metricGroupIndex={metricGroupIndex}
                index={i}
                key={`queryBreakdown:${i}`}
                dimensionType="dimensions"
              />
            ))}
            <Button
              startIcon={<Add />}
              sx={{ mt: 1 }}
              onClick={() => addDimension(metricGroupIndex, "dimensions")}
              color="inherit"
            >
              Add Breakdown
            </Button>
          </>
        )}
      </Box>
      <Box mb={2}>
        <Typography mb={1} fontSize={18}>
          Filters
        </Typography>
        {isQueryGroup && <DefaultQueryGroupFilters />}
        {isLoading ? (
          <Typography>Loading filters...</Typography>
        ) : (
          <>
            {metricGroup.filters.map(
              (filter, i) =>
                (!isQueryGroup || i > 2) && (
                  <DimensionSelect
                    metricGroupIndex={metricGroupIndex}
                    index={i}
                    key={`queryFilter:${i}`}
                    dimensionType="filters"
                  />
                )
            )}
            <Box>
              <Button
                startIcon={<Add />}
                sx={{ mt: 1 }}
                onClick={() => addDimension(metricGroupIndex, "filters")}
                color="inherit"
              >
                Add Filter
              </Button>
            </Box>
          </>
        )}
      </Box>
    </>
  );
}
