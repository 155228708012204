import axios, { AxiosRequestConfig } from "axios";
import { MetricGroup, SavedQueryRequest } from "../models/Explorer";
import { Team } from "../models/Team";
import { AxiosResponse } from "axios";
import { Auth } from 'aws-amplify';
import { IdReport } from "../models/IdReport";

type ReportType = "offense" | "defense";

const base_url = process.env.REACT_APP_EXPLORER_API ?? "extapi";

axios.interceptors.request.use(async config => {
  const token = (await Auth.currentSession()).getIdToken()
  return Object.assign(config, {
    headers: {
      JwtToken: token.getJwtToken(),
      userId: token.payload.sub,
    }
  })
})

const explorerAPI = {
  get: function (endpoint: string, config?: AxiosRequestConfig) {
    return axios.get(`${base_url}/${endpoint}`, config);
  },

  post: function (endpoint: string, data: any, config?: AxiosRequestConfig) {
    return axios.post(`${base_url}/${endpoint}`, data, config);
  },
};

export function getTeamReport(reportType: ReportType, team: Team) {
  const endpoint =
    reportType === "offense"
      ? `api/team_off_report/${team.abbrev}/off`
      : `api/team_def_report/${team.abbrev}/def`;

  return explorerAPI.get(endpoint);
}

export function getFilters() {
  return explorerAPI.get(`explorer/api/get_filters`);
}

export function getMetrics() {
  return explorerAPI.get(`explorer/api/get_metrics`);
}

export function executeQuery(query: MetricGroup[], queryId?: string) {
  if (queryId) {
    return explorerAPI.post(`explorer/api/exec_query/${queryId}`, query);
  }
  return explorerAPI.post(`explorer/api/exec_query`, query);
}

export function loadQueries() {
  return explorerAPI.get(`explorer/api/load_queries`);
}

export function loadQuery(queryId: string) {
  return explorerAPI.get(`explorer/api/load_queries/${queryId}`)
}

export function saveQuery(query: SavedQueryRequest) {
  return explorerAPI.post(`explorer/api/save_query`, query);
}

export function getGameLog(limit?: number): Promise<GameLogResponse> {
  return explorerAPI.get(`api/get_games/POR`, { params: { limit: limit } });
}

export function getUpcomingGames() {
  return explorerAPI.get(`api/get_upcoming_games`);
}

export function getGameReport(gameId: string) {
  return explorerAPI.get(`api/game_report/${gameId}`);
}

export function getIdReport(reportId: string): Promise<IdReportResponse> {
  return explorerAPI.get(`api/id_report/${reportId}`);
}

export function getIdReportList() {
  return explorerAPI.get('/api/id_report/list')
}

// Response interfaces

type PastGameTuple = [id: string, date: string, opponentInfo: string];
interface GameLogResponse extends AxiosResponse<PastGameTuple[]> {}
interface IdReportResponse extends AxiosResponse<IdReport> {}
