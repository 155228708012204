import { Box, TableCell } from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { isShotChartData, isVideoData } from "../../../models/TableData";
import ShotChartIcon from "../../Icons/ShotChartIcon";
import VideoIcon from "../../Icons/VideoIcon";
import ShotChartDialog from "../ShotChart/ShotChartDialog";

interface Props {
  data: string | boolean;
  header: string;
}

export default function Cell({ data, header }: Props) {
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const toggleDialog = () => setDialogIsOpen(!dialogIsOpen);

  if (typeof data === "boolean") data = data.toString();

  if (header === 'Date' && !['true', 'false'].includes(data)) {
    data = dayjs(data).format("MMM DD, YYYY")
  }

  if (isVideoData(data))
    return (
      <TableCell align="center">
        <a
          href={data}
          target="_blank"
          rel="noreferrer"
          className="cell--icon-container"
        >
          <VideoIcon />
        </a>
      </TableCell>
    );

  if (isShotChartData(data)) {
    return (
      <TableCell align="center">
        <ShotChartDialog
          data={data}
          isOpen={dialogIsOpen}
          handleClose={toggleDialog}
        />
        <Box onClick={toggleDialog} className="cell--icon-container">
          <ShotChartIcon />
        </Box>
      </TableCell>
    );
  }

  return <TableCell align="center">{data}</TableCell>;
}
