import { Box, Grid, Typography } from "@mui/material";
import { Goal } from "../../../models/IdReport";
import { QueryStatus } from "../../../data/ExplorerStore";

export default function GoalDisplay({
  goal,
  isTeamGoalGroup,
  reportFS,
  expand,
  expand2,
}: {
  goal: Goal;
  isTeamGoalGroup: boolean;
  reportFS: QueryStatus;
  expand?: boolean;
  expand2?: boolean;
}) {
  const targetAsNum = parseFloat(goal.target);
  const secondaryTargetAsNum = parseFloat(goal.secondaryTarget);
  const outcomeAsNum = parseFloat(goal.outcome);
  const isNumber = !Number.isNaN(outcomeAsNum);
  const sampleSizeAsNum = parseInt(goal.sampleSize);
  const goalIsMet =
    goal.comparator === ">"
      ? outcomeAsNum >= targetAsNum
      : outcomeAsNum <= targetAsNum;

  const secondaryGoalIsMet =
    goal.comparator === ">"
      ? outcomeAsNum >= secondaryTargetAsNum
      : outcomeAsNum <= secondaryTargetAsNum;

  const loading = reportFS === QueryStatus.Requesting;

  const goalClass = (() => {
    if (loading || sampleSizeAsNum === 0) {
      return ""
    } else if (goalIsMet) {
      return "goal--met"
    } else if (secondaryGoalIsMet) {
      return "secondary-goal--met"
    } else return "goal--not-met"
  })();

  // TEMP - TBD INDICATOR FOR CTG METRIC
  // come up with better way of determining if the data is in yet or not
  const isTBD = goal.name === "% of PRs at 'mid' depth" && outcomeAsNum === 0;

  if (isTBD) return <TBDGoalDisplay goal={goal} loading={loading} />;

  return (
    <Grid item xs={expand || expand2 || isTeamGoalGroup ? true : 2.78}>
      <Box
        py={2}
        px={2}
        className="id-report--goal-box"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box>
          <Typography
            className="id-report--goal-target"
            fontSize="17px"
            fontWeight="medium"
          >
            {goal.comparator} {goal.target}
            {goal.formatAsPercent ? "%" : ""}
          </Typography>
          <Typography
            className="id-report--goal-label"
            fontSize="12px"
            fontFamily="Roboto"
            mb={2}
            ml={2}
          >
            GOAL
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="h4"
            fontSize="57px"
            className={goalClass}
          >
            {loading || !isNumber
              ? "--"
              : goal.formatAsPPP
              ? outcomeAsNum.toFixed(2)
              : outcomeAsNum}
            {goal.formatAsPercent && isNumber ? "%" : ""}
          </Typography>
          <Typography className="goal-name">{goal.name}</Typography>
        </Box>
      </Box>
      <Typography fontSize="12px" ml={1} fontWeight="bold" color="#a0a0a0">
        {loading ? "--" : sampleSizeAsNum}{" "}
        {sampleSizeAsNum === 1
          ? goal.sampleSizeLabel.slice(0, goal.sampleSizeLabel.length - 1)
          : goal.sampleSizeLabel}
      </Typography>
    </Grid>
  );
}

// TEMP DISPLAY FOR CTG METRIC INCOMPLETE DATA
function TBDGoalDisplay({ goal, loading }: { goal: Goal; loading: boolean }) {
  return (
    <Grid item xs={2.78}>
      <div
        style={{
          height: "100%",
          position: "relative",
          backgroundColor: "rgba(0,0,0,.08)",
          borderRadius: "10px",
        }}
      >
        <Box
          py={2}
          px={2}
          className="id-report--goal-box"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box>
            <Typography
              className="id-report--goal-target"
              fontSize="17px"
              fontWeight="medium"
            >
              {goal.comparator} {goal.target}
              {goal.formatAsPercent ? "%" : ""}
            </Typography>
            <Typography
              className="id-report--goal-label"
              fontSize="12px"
              fontFamily="Roboto"
              mb={2}
              ml={2}
            >
              GOAL
            </Typography>
          </Box>
          <Box>
            <Typography variant="h4" fontSize="24px" color="#a0a0a0">
              Data incomplete
            </Typography>
            <Typography className="goal-name">{goal.name}</Typography>
          </Box>
        </Box>
      </div>
      <Typography fontSize="12px" ml={1} fontWeight="bold" color="#a0a0a0">
        {loading ? "--" : goal.sampleSize} {goal.sampleSizeLabel}
      </Typography>
    </Grid>
  );
}
