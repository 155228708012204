import { Box, Stack, TableCell, Typography } from "@mui/material"
import { useState } from "react"
import { isShotChartData, isVideoData } from "../../../models/TableData"
import ShotChartIcon from "../../Icons/ShotChartIcon"
import VideoIcon from "../../Icons/VideoIcon"
import ShotChartDialog from "../ShotChart/ShotChartDialog"

export default function Cell({cellData}: {cellData: [data: string, unit: string]}) {
  const [data, unit] = cellData
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false)
  const toggleDialog = () => setDialogIsOpen(!dialogIsOpen)

  function CellContents() {
    if (isVideoData(data)) return (
      <a href={data} target='_blank' rel="noreferrer" style={{ color: '#666' }}>
        <VideoIcon />
      </a>
    )

    if (isShotChartData(data)) return (
      <>
        <ShotChartDialog data={data} isOpen={dialogIsOpen} handleClose={toggleDialog} />
        <Box onClick={toggleDialog} sx={{ cursor: 'pointer' }}>
          <ShotChartIcon />
        </Box>
      </>
    )

    return (
      <Stack>
        {(data === "0.0%" && unit === "of shots") || data === "nan%"
          ? <Typography>-</Typography>
          : <Typography fontSize={"18px"}>{data}</Typography>
        }
      </Stack>
    )
  }

  return (
    <TableCell align='center'>
      <CellContents />
    </TableCell>
  )
}
