import { useEffect, useState, useMemo, ChangeEvent } from "react";
import {
  Container,
  Stack,
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography,
  IconButton,
} from "@mui/material";
import { QueryStatus, useExplorerStore } from "../../../data/ExplorerStore";
import QueryBuilder from "./QueryBuilder";
import DataTable from "../../Elements/DataTable/DataTable";
import { TableData } from "../../../models/TableData";
import { camelPad } from "../../../util/strings";
import DataTableSkeleton from "../../Elements/DataTable/DataTableSkeleton";
import { Close } from "@mui/icons-material";
import { useLocation } from "react-router-dom";

export default function Explorer() {
  const data = useExplorerStore(({ data }) => data);
  const explorerStatus = useExplorerStore(
    ({ explorerStatus }) => explorerStatus
  );
  const initExplorer = useExplorerStore(({ initExplorer }) => initExplorer);
  const isLoading = useExplorerStore(
    ({ queryStatus }) => queryStatus === QueryStatus.Requesting
  );
  const queryName = useExplorerStore(({ queryName }) => queryName);
  const [error, clearError] = useExplorerStore(({ error, clearError }) => [error, clearError]);

  const [filteredData, setFilteredData] = useState<TableData>(data);
  let filterMetricList = useMemo(
    () =>
      data && data.length > 0
        ? Object.keys(data[0]).filter((k) =>
            Number.isFinite(parseFloat(data[0][k]))
            && k !== "Playcall"
          )
        : [],
    [data]
  );
  const [filterMetric, setFilterMetric] = useState<string>(
    filterMetricList.length > 0 ? filterMetricList[0] : ""
  );
  const [filterThreshold, setFilterThreshold] = useState<number | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const location = useLocation()

  useEffect(() => {
    if (explorerStatus === QueryStatus.None) {
      initExplorer(location.hash.substring(1));
    }
  }, [explorerStatus, initExplorer, location]);

  useEffect(() => {
    setFilterMetric(filterMetricList.length > 0 ? filterMetricList[0] : "");
  }, [data, filterMetricList]);

  useEffect(() => {
    setFilteredData(
      [...data]
        .filter(
          (item) =>
            Object.values(item)
              .join("#")
              .toLowerCase()
              .indexOf(searchTerm.toLowerCase()) !== -1
        )
        .filter(
          (item) =>
            Object.keys(item).indexOf(filterMetric) === -1 ||
            parseFloat(item[filterMetric]) >= (filterThreshold ?? 0)
        )
    );
  }, [data, filterMetric, filterThreshold, searchTerm]);

  return (
    <Container maxWidth="xl">
      <Typography variant="h3" sx={{ textTransform: "uppercase" }} mt={2}>
        {camelPad(queryName)}
      </Typography>
      <Stack
        direction="row"
        my={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          justifyContent="flex-start"
        >
          <TextField
            type="text"
            placeholder="filter"
            value={searchTerm}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setSearchTerm(e.target.value)
            }
          />
          <Select
            value={filterMetric}
            onChange={(e: SelectChangeEvent) => setFilterMetric(e.target.value)}
          >
            {filterMetricList.map((fm) => (
              <MenuItem value={fm} key={`filterMetric:${fm}`}>
                {fm}
              </MenuItem>
            ))}
          </Select>
          <TextField
            type="text"
            value={filterThreshold}
            placeholder="min"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const inputValue = parseFloat(e.target.value);
              const newThreshold = isNaN(inputValue) ? undefined : inputValue;
              setFilterThreshold(newThreshold);
            }}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          justifyContent="flex-end"
        >
          <QueryBuilder />
        </Stack>
      </Stack>
      {error &&
        <Stack direction="row" alignItems="center">
          <IconButton size="small" color="error" onClick={clearError}><Close fontSize="small"/></IconButton><Typography color="error" fontWeight={500}>{error.message}</Typography>
        </Stack>
      }
      {isLoading ? (
        <Container maxWidth="xl" sx={{ mt: 5 }}>
          {/* <CircularProgress size={75} thickness={5} /> */}
          <DataTableSkeleton num={10} />
        </Container>
      ) : (
        <DataTable data={filteredData} />
      )}
    </Container>
  );
}
