import produce from "immer";
import create from "zustand";
import {
  blankDimension,
  blankMetricGroup,
  defaultMetricGroup,
  defaultQueryGroup,
  Dimension,
  DimensionType,
  ExplorerError,
  FilterResponse,
  MetricGroup,
  SavedQuery,
  SavedQueryRequest,
  sortQuery,
} from "../models/Explorer";
import { TableData } from "../models/TableData";
import {
  executeQuery,
  getFilters,
  getMetrics,
  loadQueries,
  loadQuery,
  saveQuery,
} from "../util/Requests";
import { CacheKey, readFromCache, writeToCache } from "./Cache";

export enum QueryStatus {
  None = 0,
  Requesting = 1,
  Complete = 2,
  Failed = 3,
}

interface ExplorerState {
  filters: FilterResponse | null;
  metrics: string[];
  metricGroups: MetricGroup[];
  data: TableData;
  savedQueries: SavedQuery[];
  queryStatus: QueryStatus;
  explorerStatus: QueryStatus;
  queryName: string;
  queryId?: string;
  pendingController?: AbortController;
  error?: ExplorerError;
}

interface ExplorerActions {
  updateMetricGroup: (
    metricGroupIndex: number,
    metricGroup: MetricGroup
  ) => void;
  updateDimension: (
    metricGroupIndex: number,
    dimensionType: DimensionType,
    dimensionIndex: number,
    dimension: Dimension
  ) => void;
  execQuery: (queryId?: string) => void;
  initExplorer: (hash?: string) => void;
  addMetricGroup: () => void;
  addDimension: (
    metricGroupIndex: number,
    dimensionType: DimensionType
  ) => void;
  removeDimension: (
    metricGroupIndex: number,
    dimensionType: DimensionType,
    dimensionIndex: number
  ) => void;
  loadSavedQuery: (index: number) => void;
  saveQuery: (name: string) => void;
  clearError: () => void;
}

export const useExplorerStore = create<ExplorerState & ExplorerActions>(
  (set, get) => ({
    /* STATE */
    filters: null,
    metrics: [],
    metricGroups: [defaultQueryGroup, defaultMetricGroup],
    data: [],
    savedQueries: [],
    queryStatus: QueryStatus.None,
    explorerStatus: QueryStatus.None,
    queryName: "",

    /* ACTIONS */
    updateMetricGroup: (metricGroupIndex: number, metricGroup: MetricGroup) => {
      set(
        produce((draft) => {
          draft.metricGroups.splice(metricGroupIndex, 1, metricGroup);
        })
      );
    },

    addMetricGroup: () => {
      set(
        produce((draft) => {
          draft.metricGroups.push(blankMetricGroup);
        })
      );
    },

    execQuery: (queryId) => {
      const { metricGroups: query, pendingController } = get();
      if (pendingController) {
        pendingController.abort();
      }
      const controller = new AbortController();
      set({
        queryStatus: QueryStatus.Requesting,
        queryId: queryId,
        pendingController: controller,
        error: undefined,
      });
      const sortedQuery = sortQuery(query)
      executeQuery(sortedQuery, queryId)
        .then((response) => {
          if (controller.signal.aborted) return;
          let data = response.data.data ? JSON.parse(response.data.data) : response.data
          set({
            data: data,
            queryStatus: QueryStatus.Complete,
            queryId: response.data.query_id,
            pendingController: undefined,
          });
        })
        .catch((e) => {
          console.log("error executing query: ", e);
          set(
            produce((draft) => {
              draft.queryStatus = QueryStatus.Failed;
              draft.error = { message: `Error executing query: ${e.message}` };
              draft.data = [];
            })
          );
        });
    },

    initExplorer: async (hash) => {
      set({ explorerStatus: QueryStatus.Requesting, queryId: hash });
      readFromCache(CacheKey.Filters).then((cachedFilters) => {
        if (cachedFilters) {
          set({ filters: cachedFilters as FilterResponse });
        }
      });

      Promise.all([getFilters(), getMetrics(), loadQueries()])
      .then(
        ([{ data: filters }, { data: metrics }, { data: savedQueries }]) => {
          writeToCache(CacheKey.Filters, filters);
          set({
            metrics,
            filters,
            savedQueries,
            explorerStatus: QueryStatus.Complete,
          });
        }
      )
      .catch(() => {
        set({ explorerStatus: QueryStatus.Failed });
      });

      if (hash) {
        const savedQuery = (await loadQuery(hash)).data
        await set({
          metricGroups: sortQuery(JSON.parse(savedQuery.query)),
          queryName: savedQuery.name ?? "",
        })
      }
      get().execQuery(hash);
    },

    updateDimension: (
      metricGroupIndex: number,
      dimensionType: DimensionType,
      dimensionIndex: number,
      dimension: Dimension
    ) => {
      set(
        produce((draft) => {
          draft.metricGroups[metricGroupIndex][dimensionType][dimensionIndex] =
            dimension;
        })
      );
    },

    addDimension: (metricGroupIndex: number, dimensionType: DimensionType) => {
      set(
        produce((draft) => {
          draft.metricGroups[metricGroupIndex][dimensionType].push(
            blankDimension
          );
        })
      );
    },

    removeDimension: (
      metricGroupIndex: number,
      dimensionType: DimensionType,
      dimensionIndex: number
    ) => {
      set(
        produce((draft) => {
          draft.metricGroups[metricGroupIndex][dimensionType].splice(
            dimensionIndex,
            1
          );
        })
      );
    },

    loadSavedQuery: (index: number) => {
      const savedQueries = get().savedQueries;
      if (!Object.hasOwn(savedQueries, index)) {
        return;
      }
      const query = JSON.parse(savedQueries[index].QUERY);
      const q_name = savedQueries[index].NAME;
      const filteredQuery = query.filter(
        (mg: any) => mg.metrics !== "this_is_hidden"
      );
      set({ metricGroups: filteredQuery, queryName: q_name });
    },

    saveQuery: (name: string) => {
      const q = get().metricGroups;
      const query: SavedQueryRequest = {
        name: name,
        query: q,
      };

      saveQuery(query)
        .then((response) => {
          set({ queryName: name });
          loadQueries().then(({ data }) => set({ savedQueries: data }));
          console.log("save query response: ", response);
        })
        .catch((err) => {
          console.log("save query failed with error: ", err);
        });
    },

    clearError: () => {
      set({ error: undefined });
    },
  })
);
