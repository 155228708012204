import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#CF0A2C",
      dark: "#000000",
      light: "#A0A0A0",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#000000",
      dark: "#000000",
      light: "#ffffff",
      contrastText: "#000000",
    },
  },
  typography: {
    fontFamily: ["Roboto", "Open Sans", "Oswald", "sans-serif"].join(","),
    h1: {
      fontSize: "3.75rem",
      fontFamily: "'Oswald', sans-serif",
      fontWeight: 700,
      textTransform: "uppercase",
    },
    h2: {
      fontSize: "2.5rem",
      fontFamily: "'Oswald', sans-serif",
      fontWeight: 700,
      textTransform: "uppercase",
    },
    h3: {
      fontSize: "1.875rem",
      fontFamily: "'Oswald', sans-serif",
      fontWeight: 700,
    },
    h4: {
      fontSize: "1.25rem",
      fontFamily: "'Oswald', sans-serif",
      fontWeight: 700,
    },
    h5: {
      fontSize: "1rem",
      fontFamily: "'Oswald', sans-serif",
      fontWeight: 700,
    },
    h6: {
      fontSize: "1rem",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: 600,
      textTransform: "capitalize",
    },
    subtitle1: {
      fontSize: "0.9rem",
      fontFamily: "'Roboto', sans-serif",
      textTransform: "uppercase",
      fontWeight: 500,
      letterSpacing: "0.07em",
      lineHeight: 1.3,
    },
    subtitle2: {
      fontSize: "0.8rem",
      fontFamily: "'Roboto', sans-serif",
      textTransform: "uppercase",
      fontWeight: 500,
      letterSpacing: "0.07em",
    },
    body1: {
      fontSize: "1rem",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: 400,
    },
    body2: {
      fontSize: "0.875rem",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: 400,
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: "40px",
        }
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          height: "auto",
        }
      }
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          '&.data-table-container': {
            width: 'fit-content'
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          whiteSpace: "nowrap",
          padding: 0,
          fontSize: "18px",
          backgroundColor: "#f2f2f2",
        },
        head: {
          fontWeight: "bold",
          borderBottom: "none",
          fontSize: "20px",
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.section--splits-header td': {
            // borderBottom: '1px solid black',
            borderBottom: '8px solid #f2f2f2',
            // borderBottom: "none",
            fontSize: "20px",
          },
        },
        head: {
          backgroundColor: "#dfdfdf",
          '&.--header-units-row': {
            borderBottom: "2px solid black",
          },
          '&.--header-title-row': {
            // borderTop: '1px solid black',
          },
        },
      }
    },
  }
});
