export function isTableData(data: any): data is TableData {
  return (
    Array.isArray(data) &&
    !!data.length &&
    typeof data[0] === "object" &&
    !!Object.entries(data[0]).length
  );
}

export type TableData = TableRowData[];

export type TableRowData = {
  [columnName: string]: string;
};

export type ShotChartData = string;

export function isShotChartData(data: string): data is ShotChartData {
  try {
    const cleaned = data.replace(/(['"])?([a-z0-9A-Z_]+)(['"])?:/g, '"$2": ');
    const parsed = JSON.parse(cleaned);
    const containsObjects = parsed.every((el: any) => typeof el === "object");
    return Array.isArray(parsed) && containsObjects;
  } catch (e) {
    return false;
  }
}

type VideoData = string;

export function isVideoData(data: string): data is VideoData {
  try {
    return data.includes("https://www.secondspectrum.com");
  } catch (e) {
    return false;
  }
}
