import localforage from "localforage";

export enum CacheKey {
  Filters = "filters",
  Metrics = "metrics",
  Queries = "queries",
}

export const writeToCache = (key: CacheKey, data: any) => {
  console.log(`writing ${key} to cache`)
  console.log("length: ", JSON.stringify(data).length)
  try{
    localforage.setItem(key, data);
  } catch(e) {
    console.log("error writing to cache: ", e)
  }
};

export const readFromCache = async (key: CacheKey) => {
  // const data = localforage.getItem(key);
  const data = await localforage.getItem(key)
  console.log(`reading from ${key}: ${data}`)
  return data
};

export const clearCache = (key: CacheKey) => {
  localStorage.removeItem(key);
};
