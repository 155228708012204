import { Box, Container, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ReportCardSection } from "../../../models/Reports";
import { getTeamFromString } from "../../../models/Team";
import { getGameReport } from "../../../util/Requests";
import TabContent from "../../Elements/TabContent";
import ReportCard from "../../Elements/ReportCard/ReportCard";
import GameReportSkeleton from "./GameReportSkeleton";

interface GameInfo {
  away_team: string;
  away_score: string;
  home_team: string;
  home_score: string;
}

interface GameReportI {
  away: { [s: string]: ReportCardSection[] };
  game_info: GameInfo;
  home: { [s: string]: ReportCardSection[] };
}

export default function GameReport() {
  const { gameId } = useParams();
  const [gameReport, setGameReport] = useState<GameReportI | null>(null);
  const [currentTab, setCurrentTab] = useState<"home" | "away">("home");

  useEffect(() => {
    if (!gameId) return;
    console.log("getting game report");

    getGameReport(gameId)
      .then(({ data }) => {
        setGameReport(JSON.parse(data));
        console.log("game report response: ", JSON.parse(data));
      })
      .catch((err) => {
        console.log("received error from game report request: ", err);
      });
  }, [gameId]);

  if (!gameReport) return <GameReportSkeleton />;

  const { away_team, away_score, home_team, home_score } = gameReport.game_info;
  const homeTeam = getTeamFromString(home_team);
  const awayTeam = getTeamFromString(away_team);

  return (
    <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" my={3} spacing={1}>
        <Box
          component="img"
          src={awayTeam?.logoSrc}
          sx={{
            width: "75px",
            height: "75px",
            objectFit: "contain",
          }}
        />
        <Stack alignItems="center">
          <Typography variant="h2">{awayTeam.abbrev}</Typography>
          <Typography variant="h3" sx={{ fontWeight: 400 }}>
            {away_score}
          </Typography>
        </Stack>
        <Typography variant="h2" sx={{ fontWeight: 400 }}>
          @
        </Typography>
        <Box
          component="img"
          src={homeTeam?.logoSrc}
          sx={{
            width: "75px",
            height: "75px",
            objectFit: "contain",
          }}
        />
        <Stack alignItems="center">
          <Typography variant="h2">{homeTeam.abbrev}</Typography>
          <Typography variant="h3" sx={{ fontWeight: 400 }}>
            {home_score}
          </Typography>
        </Stack>
      </Stack>

      <Tabs
        value={currentTab}
        onChange={(e: React.SyntheticEvent, newValue: "home" | "away") =>
          setCurrentTab(newValue)
        }
        sx={{ mb: 2 }}
      >
        <Tab value="home" label={homeTeam.abbrev} />
        <Tab value="away" label={awayTeam.abbrev} />
      </Tabs>

      <TabContent currentTab={currentTab} tabName="home">
        <Typography variant="h2" my={2}>
          Overall
        </Typography>
        <ReportCard
          reportCard={gameReport.home.overall ?? []}
          tableClassName="game-report"
        />
        <Typography variant="h2" my={2}>
          Actions
        </Typography>
        <ReportCard
          reportCard={gameReport.home.actions ?? []}
          tableClassName="game-report"
        />
        <Typography variant="h2" my={2}>
          Closeouts
        </Typography>
        <ReportCard
          reportCard={gameReport.home.closeouts ?? []}
          tableClassName="game-report"
        />
      </TabContent>
      <TabContent currentTab={currentTab} tabName="away">
        <Typography variant="h2" my={2}>
          Overall
        </Typography>
        <ReportCard
          reportCard={gameReport.away.overall ?? []}
          tableClassName="game-report"
        />
        <Typography variant="h2" my={2}>
          Actions
        </Typography>
        <ReportCard
          reportCard={gameReport.away.actions ?? []}
          tableClassName="game-report"
        />
        <Typography variant="h2" my={2}>
          Closeouts
        </Typography>
        <ReportCard
          reportCard={gameReport.away.closeouts ?? []}
          tableClassName="game-report"
        />
      </TabContent>
    </Container>
  );
}
