interface Props {
  currentTab: string;
  tabName: string;
  className?: string;
  children: React.ReactNode;
}

export default function TabContent({
  currentTab,
  tabName,
  className,
  children,
}: Props) {
  return (
    <div hidden={tabName !== currentTab} className={className}>
      {children}
    </div>
  );
}
