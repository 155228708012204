import create from "zustand";
import { ReportType } from "../models/Reports";
import { Team } from "../models/Team";

interface ReportState {
  team: Team | null;
  setTeam: (t: Team | null) => void;
  setReportType: (r: ReportType | null) => void;
  reportType: ReportType | null;
}

export const useReportStore = create<ReportState>((set) => ({
  team: null,
  setTeam: (team: Team | null) => set((state) => ({ team })),
  reportType: null,
  setReportType: (reportType: ReportType | null) =>
    set((state) => ({ reportType })),
}));
