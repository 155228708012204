import { Skeleton } from "@mui/material"

export default function GamesSkeleton({ num = 1 }: { num?: number}) {
  const arr = Array.from({length: num}, (x, i) => i)
  return (
    <>
      {arr.map((n) => <Skeleton variant='rectangular' key={`gameSkel:${n}`} className='game-display--container' height={85} sx={{ border: "1px solid transparent" }}/>)}
    </>
  )
}
