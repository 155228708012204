import { Container, Skeleton, Stack, Typography } from "@mui/material";
import DataTableSkeleton from "../../Elements/DataTable/DataTableSkeleton";

export default function GameReportSkeleton() {
  return (
    <Container maxWidth="xl">
      <Stack direction='row' alignItems='center' sx={{ my: "24px" }}>
        <Skeleton variant="rectangular" width={153} height={83} sx={{ borderRadius: "5px" }} />
        <Typography variant='h2' sx={{ fontWeight: 400, mx: "8px" }}>@</Typography>
        <Skeleton variant="rectangular" width={153} height={83} sx={{ borderRadius: "5px" }} />
      </Stack>

      <Skeleton variant="rectangular" width={180} height={48} sx={{ marginBottom: "32px", borderRadius: "5px" }} />
      <Typography variant='h2' mb={1}>Overall</Typography>
      <DataTableSkeleton num={1} />
      <DataTableSkeleton num={3} />
      <DataTableSkeleton num={10} />
    </Container>
  )
}
