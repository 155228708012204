import "./reportCard.scss";
import React from "react";
import {
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ReportCardSection } from "../../../models/Reports";
import Cell from "./ReportCardCell";

interface Props {
  reportCard: Array<ReportCardSection>;
  showSectionHeaders?: boolean;
  tableClassName?: string;
}

export default function ReportCard({
  reportCard,
  showSectionHeaders,
  tableClassName = "",
}: Props) {
  return (
    <>
      {reportCard.map((section, sectionIndex) => {
        const headshotSrc = `https://cdn.nba.com/headshots/nba/latest/1040x760/${section.nbaPlayerId}.png`;
        if (!section.rows || section.rows.length === 0)
          return reportCard.length > 1 ? (
            <React.Fragment key={`section:${section.title}(${sectionIndex})`}>
              <Typography variant={"h3"}>{section.title}</Typography>
              <Typography>No rows available</Typography>
            </React.Fragment>
          ) : (
            <Typography
              mb={5}
              key={`section:${section.title}(${sectionIndex})`}
            >
              No data available
            </Typography>
          );

        return (
          <React.Fragment key={`section:${section.title}(${sectionIndex})`}>
            {(showSectionHeaders ||
              (reportCard.length > 1 && section.title !== "Team")) && (
              <Stack direction="row" alignItems="flex-end" mb={2}>
                {section.nbaPlayerId && (
                  <img
                    src={headshotSrc}
                    alt={`${section.title}-headshot`}
                    className="player-headshot"
                  />
                )}
                <Typography variant={"h3"}>{section.title}</Typography>
              </Stack>
            )}
            <TableContainer sx={{ mb: reportCard.length > 1 ? 7 : 5 }}>
              <Table className={`${tableClassName} report-card-table`}>
                {section.rows.map((row, rowIndex) => (
                  <React.Fragment key={`${section.title}:${rowIndex}`}>
                    {section.splits[rowIndex] && (
                      <TableRow className="section--splits-header">
                        <TableCell colSpan={row.length} align="center">
                          {section.splits[rowIndex]}
                        </TableCell>
                      </TableRow>
                    )}
                    {(section.splits[rowIndex] || rowIndex === 0) && (
                      <TableHead sx={{ display: "table-row-group" }}>
                        <TableRow className="--header-title-row">
                          {section.headers.map(([header, span]) => (
                            <TableCell
                              colSpan={span}
                              align="center"
                              key={`${section.title}:${rowIndex}:${header}`}
                            >
                              <Stack>
                                <Typography fontSize={"20px"}>
                                  {header === "ShotChart"
                                    ? "Shot Chart"
                                    : header}
                                </Typography>
                              </Stack>
                            </TableCell>
                          ))}
                        </TableRow>
                        <TableRow className="--header-units-row">
                          {row.map(([data, units], cellIndex) => (
                            <TableCell
                              align="center"
                              key={`${section.title}:${rowIndex}:headerUnits:${cellIndex}`}
                            >
                              <Typography
                                variant="subtitle2"
                                color="GrayText"
                                sx={{ fontSize: "12px" }}
                              >
                                {units}
                              </Typography>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                    )}
                    <TableRow
                      className={
                        rowIndex % 2 ? "report-row--odd" : "report-row--even"
                      }
                    >
                      {row.map((cell, cellIndex) => (
                        <Cell
                          cellData={cell}
                          key={`${section.title}:${rowIndex}:${cellIndex}`}
                        />
                      ))}
                    </TableRow>
                  </React.Fragment>
                ))}
              </Table>
            </TableContainer>
          </React.Fragment>
        );
      })}
    </>
  );
}
