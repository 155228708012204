import { useState, useEffect } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material"
import { ExpandMore } from "@mui/icons-material";
import { getTeamFromString, teamlist } from "../../models/Team"
import { useNavigate } from "react-router-dom";
import { useReportStore } from "../../data/ReportStore";
import { getReportTypeFromString } from "../../models/Reports";


export default function ReportPicker({ collapseByDefault }: { collapseByDefault?: boolean }) {
  const { team, setTeam, reportType, setReportType} = useReportStore((state) => state)
  const navigate = useNavigate()
  const [userExpanded, setUserExpanded] = useState<null | boolean>(collapseByDefault ? false : null)
  const [scrollExpanded, setScrollExpanded] = useState<boolean>(true)
  // if the user clicked to expand, then use that value, otherwise fall back to scroll behavior
  const expanded = (userExpanded !== null) ? userExpanded : scrollExpanded

  const scrollHandler = () => {
    // any little bit of scrolling on this page can trigger the shrink
    setScrollExpanded(window.scrollY < 8)
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler)
  }, [])

  useEffect(() => {
    if (team && reportType) {
      setUserExpanded(false)
    }
  }, [team, reportType])

  const handleTeamSelect = (e: SelectChangeEvent) => {
    setTeam(getTeamFromString(e.target.value))
    if (!reportType) return
    navigate(`/reports/${e.target.value}/${reportType}`)
  }
  const handleReportTypeSelect = (e: SelectChangeEvent) => {
    const reportType = getReportTypeFromString(e.target.value)
    setReportType(reportType)
    if (!team) return
    navigate(`/reports/${team.abbrev}/${e.target.value}`)
  }

  return (
    <Container
      maxWidth='xl'
      sx={{
        padding: '12px 64px !important', backgroundColor: 'white', position: 'sticky', top: 16, mb: "32px", borderRadius: "5px",
        '& .MuiPaper-root': {
          alignItems: "center",
        },
       }}
    >
      <Accordion
        sx={{ boxShadow: 'none', backgroundColor: 'inherit', display: 'flex' }}
        // if this is the first time it's touched (it was null) then base it on whether scroll expanded it
        onChange={() => setUserExpanded(userExpanded !== null ? !userExpanded : !scrollExpanded)}
        expanded={expanded}
        >
        <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: 0, display: 'inline-flex', justifyContent: 'flex-start', alignItems: 'center', alignContent: 'center', '& .MuiAccordionSummary-content': { order: 2 } }}>
          <Box
            component="img"
            src={team?.logoSrc ?? '/images/nba-logo.png'}
            sx={{
              width: expanded ? "125px" : "50px",
              height: expanded ? "125px" : "50px",
              objectFit: "contain",
              transition: "all .5s"
            }}
            mx={2}
            />
          {!expanded &&
            <Typography variant="h4" marginY={0} sx={{ fontWeight: 400, textTransform: 'capitalize' }} alignSelf='center'>
              {team && reportType ? `${team.city} ${team.name} ${reportType.substring(0, reportType.length -1)}ive report` : "Select report"}
            </Typography>
          }
        </AccordionSummary>
        <AccordionDetails>
          <Stack>
            <Select value={team?.abbrev ?? 'none'} onChange={handleTeamSelect} sx={{ mb: "16px" }}>
              <MenuItem value='none' disabled>Select team</MenuItem>
              {teamlist.map(t => {
                const team = getTeamFromString(t)
                return <MenuItem value={team.abbrev} key={team.abbrev}>{`${team.city} ${team.name}`}</MenuItem>
              })}
            </Select>
            <Select value={reportType || 'none'} onChange={handleReportTypeSelect}>
              <MenuItem value='none' disabled>Select report type</MenuItem>
              <MenuItem value='offense'>Offensive Report</MenuItem>
              <MenuItem value='defense'>Defensive Report</MenuItem>
            </Select>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Container>
  )
}
