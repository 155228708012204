// Imports
import { ReactNode } from "react";
import { Typography, RadioGroup, FormControl, FormLabel } from "@mui/material";

// Stylesheet
import "./styledRadioButtons.scss";

interface StyledRadioButtonsGroupProps {
  fieldLabel?: string;
  error?: boolean;
  id: string;
  name?: string;
  row?: boolean;
  children?: ReactNode;
  textSize?: "sm" | "lg";
  value?: string;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => void;
  className?: string;
}

export const StyledRadioButtonsGroup = ({
  fieldLabel,
  error,
  id,
  name,
  row = false,
  children,
  textSize,
  value,
  onChange,
  className,
}: StyledRadioButtonsGroupProps) => {
  return (
    <div>
      <FormControl error={error} className={`styled-radio-buttons--container${className ? ` ${className}` : ""}`}>
        <FormLabel id={id}>
          <Typography
            variant={textSize === "sm" ? "subtitle2" : "subtitle1"}
            className="styled-radio-buttons--label"
          >
            {fieldLabel}
          </Typography>
        </FormLabel>
        <RadioGroup
          aria-labelledby={id}
          name={name}
          row={row}
          value={value}
          onChange={onChange}
        >
          {children}
        </RadioGroup>
      </FormControl>
    </div>
  );
};
