import { Box, Skeleton } from "@mui/material";

export default function DataTableSkeleton({ num = 1 }: { num?: number }) {
  const arr = Array.from({ length: num }, (x, i) => i);
  return (
    <Box mb={4}>
      <Skeleton
        variant="rectangular"
        height={52}
        sx={{ marginBottom: "16px" }}
        animation={false}
      />
      {arr.map((n) => (
        <Skeleton
          variant="rectangular"
          className="data-table-skeleton--row"
          key={`tableRowSkel:${n}`}
          height={24}
          animation="wave"
        />
      ))}
    </Box>
  );
}
