import * as React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Tooltip,
  MenuItem,
  Link,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import "./navbar.scss";

interface NavPage {
  label: string;
  route: string;
}

const pages: NavPage[] = [
  { label: "Reports", route: "/reports" },
  { label: "Explorer", route: "/explorer" },
  { label: "Identity Report", route: "/id-report" },
];

interface NavBarProps {
  given_name?: string;
  family_name?: string;
  handleSignOut?: () => void;
}

export default function NavBar({
  given_name,
  family_name,
  handleSignOut,
}: NavBarProps) {
  const userInitials = `${given_name ? given_name[0] : ""}${
    family_name ? family_name[0] : ""
  }`;
  // NOTE: Controls to open Navigation Bar menu items.
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" className="header--container">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* NOTE: Blazers Desktop Logo */}
          <Box sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}>
            <img
              src="/images/primary-logo.png"
              alt="Primary Team Logo"
              height="50px"
              width="auto"
            />
          </Box>

          <Typography
            variant="h2"
            noWrap
            component="a"
            href="/"
            color="textPrimary"
            sx={{
              mr: 2,
              ml: 2,
              display: { xs: "none", md: "flex" },
              textDecoration: "none",
            }}
          >
            Explorer
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              className="mobile-menu-icon"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {/* NOTE: Main navigation pages on mobile. */}
              {pages.map(({ label, route }) => (
                <MenuItem key={label} onClick={handleCloseNavMenu}>
                  <Link
                    component={RouterLink}
                    to={route}
                    underline="none"
                    color="black"
                  >
                    <Typography textAlign="center">{label}</Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          {/* NOTE: Blazers Mobile Logo */}
          <Box sx={{ display: { xs: "flex", md: "none" }, mr: 0 }}>
            <img
              src="/images/primary-logo.png"
              alt="Primary Team Logo"
              height="30px"
              width="auto"
            />
          </Box>

          {/* NOTE: Explorer Logo Text for mobile */}
          <Typography
            variant="h3"
            noWrap
            component="a"
            href=""
            color="textPrimary"
            sx={{
              mr: 2,
              ml: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              textDecoration: "none",
              textTransform: "uppercase",
            }}
          >
            Explorer
          </Typography>

          {/* NOTE: Main navigation pages displayed on desktop. */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              gap: "20px",
              marginLeft: "10px",
            }}
          >
            {pages.map(({ label, route }) => (
              <Link
                component={RouterLink}
                underline="none"
                to={route}
                key={label}
                onClick={handleCloseNavMenu}
                color="black"
                sx={{ my: 2, display: "block" }}
              >
                <Typography variant="subtitle1">{label}</Typography>
              </Link>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                {/* NOTE: User Avatar. If no image, falls back to letter with red background. */}
                <Avatar className="header--avatar">{userInitials}</Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {/* NOTE: User menu items. Defined as settings. */}
              <MenuItem
                onClick={() => {
                  handleSignOut && handleSignOut();
                }}
              >
                <Typography textAlign="center">
                  Sign out as {given_name} {family_name}
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
