export type TeamAbbrev = 'ATL'| 'BKN'| 'BOS'| 'CHA'| 'CHI'| 'CLE'| 'DAL'| 'DEN'| 'DET'| 'GSW'|
'HOU'| 'IND'| 'LAC'| 'LAL'| 'MEM'| 'MIA'| 'MIL'| 'MIN'| 'NOP'| 'NYK'|
'OKC'| 'ORL'| 'PHI'| 'PHX'| 'POR'| 'SAC'| 'SAS'| 'TOR'| 'UTA'| 'WAS'

export type Team = {
  abbrev: TeamAbbrev,
  city: string,
  name: string,
  logoSrc: string,
}

export const teamlist: TeamAbbrev[] = ['ATL', 'BKN', 'BOS', 'CHA', 'CHI', 'CLE', 'DAL', 'DEN', 'DET', 'GSW',
'HOU', 'IND', 'LAC', 'LAL', 'MEM', 'MIA', 'MIL', 'MIN', 'NOP', 'NYK',
'OKC', 'ORL', 'PHI', 'PHX', 'POR', 'SAC', 'SAS', 'TOR', 'UTA', 'WAS']

const logosPath = `https://a.espncdn.com/i/teamlogos/nba/500`

export function getTeamFromString(teamParam: string): Team {
  const abbrev = teamParam as TeamAbbrev
  if (!teamlist.includes(abbrev)) throw new Error("Error finding team: invalid team abbreviation")
  let logoSrc = `${logosPath}/${abbrev}.png`
  if (abbrev === 'UTA') logoSrc = `${logosPath}/UTAH.png`
  if (abbrev === 'NOP') logoSrc = `${logosPath}/NO.png`
  return Object.assign(teamMap[abbrev], { abbrev, logoSrc }) as Team
}

const teamMap: {[key in TeamAbbrev]: Partial<Team>} = {
  'ATL': {
    city: 'Atlanta',
    name: 'Hawks'
  },
  'BKN': {
    city: 'Brooklyn',
    name: 'Nets',
  },
  'BOS': {
    city: 'Boston',
    name: 'Celtics',
  },
  'CHA': {
    city: 'Charlotte',
    name: 'Hornets',
  },
  'CHI': {
    city: 'Chicago',
    name: 'Bulls',
  },
  'CLE': {
    city: 'Cleveland',
    name: 'Cavaliers',
  },
  'DAL': {
    city: 'Dallas',
    name: 'Mavericks',
  },
  'DEN': {
    city: 'Denver',
    name: 'Nuggets',
  },
  'DET': {
    city: 'Detroit',
    name: 'Pistons',
  },
  'GSW': {
    city: 'Golden State',
    name: 'Warriors',
  },
  'HOU': {
    city: 'Houston',
    name: 'Rockets',
  },
  'IND': {
    city: 'Indiana',
    name: 'Pacers',
  },
  'LAC': {
    city: 'Los Angeles',
    name: 'Clippers',
  },
  'LAL': {
    city: 'Los Angeles',
    name: 'Lakers',
  },
  'MEM': {
    city: 'Memphis',
    name: 'Grizzlies',
  },
  'MIA': {
    city: 'Miami',
    name: 'Heat',
  },
  'MIL': {
    city: 'Milwaukee',
    name: 'Bucks',
  },
  'MIN': {
    city: 'Minnesota',
    name: 'Timberwolves',
  },
  'NOP': {
    city: 'New Orleans',
    name: 'Pelicans',
  },
  'NYK': {
    city: 'New York',
    name: 'Knicks',
  },
  'OKC': {
    city: 'Oklahoma City',
    name: 'Thunder',
  },
  'ORL': {
    city: 'Orlando',
    name: 'Magic',
  },
  'PHI': {
    city: 'Philadelphia',
    name: '76ers',
  },
  'PHX': {
    city: 'Phoenix',
    name: 'Suns',
  },
  'POR': {
    city: 'Portland',
    name: 'Trail Blazers',
  },
  'SAC': {
    city: 'Sacramento',
    name: 'Kings',
  },
  'SAS': {
    city: 'San Antonio',
    name: 'Spurs',
  },
  'TOR': {
    city: 'Toronto',
    name: 'Raptors',
  },
  'UTA': {
    city: 'Utah',
    name: 'Jazz',
  },
  'WAS': {
    city: 'Washington',
    name: 'Wizards',
  },
}
