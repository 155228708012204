import "./gameDisplay.scss";
import { Stack, Box, Divider, Button } from "@mui/material";
import { getTeamFromString } from "../../../models/Team";
import { Link } from "react-router-dom";
import { Game } from "../../../models/Game";
import GameDisplay from "./GameDisplay";

interface Props {
  game: Game;
}

export default function UpcomingGame({ game }: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, opp] = game.opponentInfo.split(" ");
  const oppTeam = getTeamFromString(opp);

  return (
    <Stack direction="row" className="game-display--container">
      <GameDisplay game={game} />
      <Box className="game-display--button-container">
        <Button
          component={Link}
          to={`/reports/${oppTeam.abbrev}/offense`}
          className="game-display--report-link"
        >
          Offensive Report
        </Button>
        <Divider className="game-display--divider" />
        <Button
          component={Link}
          to={`/reports/${oppTeam.abbrev}/defense`}
          className="game-display--report-link"
          color="secondary"
        >
          Defensive Report
        </Button>
      </Box>
    </Stack>
  );
}
