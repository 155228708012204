import { FormControlLabel, Switch, Typography } from "@mui/material";
import { FilterResponse, FilterOption } from "../../../models/Explorer";
import { useExplorerStore } from "../../../data/ExplorerStore";
import { StyledCheckbox } from "../../Elements/StyledCheckbox/StyledCheckbox";

export default function DefaultQueryGroupFilters() {
  const {
    seasonFilterIndex,
    seasonFilter,
    seasonFilterOption,
    seasonTypeFilterIndex,
    seasonTypeFilter,
    seasonTypeFilterOption,
    garbageFilterIndex,
    garbageFilter,
    updateDimension,
  } = useExplorerStore(({ metricGroups, filters, updateDimension }) => {
    const queryFilters = metricGroups[0].filters;
    const seasonFilterIndex = queryFilters.findIndex(
      (f) => f.name?.value === "Season"
    );
    const seasonTypeFilterIndex = queryFilters.findIndex(
      (f) => f.name?.value === "SeasonType"
    );
    const garbageFilterIndex = queryFilters.findIndex(
      (f) => f.name?.value === "Garbage"
    );
    const seasonFilter = queryFilters[seasonFilterIndex];
    const seasonTypeFilter = queryFilters[seasonTypeFilterIndex];
    const garbageFilter = queryFilters[garbageFilterIndex];
    return {
      seasonFilterIndex,
      seasonFilter,
      seasonFilterOption: getFilterOption(filters, "Season"),
      seasonTypeFilterIndex,
      seasonTypeFilter,
      seasonTypeFilterOption: getFilterOption(filters, "SeasonType"),
      garbageFilterIndex,
      garbageFilter,
      updateDimension,
    };
  });

  const isGarbageFiltered = ((): boolean => {
    if (
      !garbageFilter?.values ||
      typeof garbageFilter?.values === "number" ||
      typeof garbageFilter?.values === "string"
    )
      return false;
    return !garbageFilter.values.some((v) => v.value === "True");
  })();

  function getFilterOption(
    filters: FilterResponse | null,
    filterName: string | undefined
  ): FilterOption | undefined {
    if (!filters || !filterName) return undefined;
    return filters[filterName];
  }

  function checkBoxChange(
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    checkBoxType: "season" | "seasonType"
  ) {
    const [filter, filterIndex] =
      checkBoxType === "season"
        ? [seasonFilter, seasonFilterIndex]
        : [seasonTypeFilter, seasonTypeFilterIndex];
    const filterForUpdate = Object.assign({}, filter);
    if (!Array.isArray(filterForUpdate.values)) return;
    const filterValuesForUpdate = filterForUpdate.values?.slice() ?? [];
    const { value } = event.target;

    if (checked) {
      filterValuesForUpdate.push({ label: value, value: value });
    } else {
      const valueIndex = filterForUpdate.values?.findIndex(
        (v) => v.value === value
      );
      if (typeof valueIndex === "number") {
        filterValuesForUpdate.splice(valueIndex, 1);
      }
    }
    filterForUpdate.values = filterValuesForUpdate;
    updateDimension(0, "filters", filterIndex, filterForUpdate);
  }

  function toggleChange(_: any, checked: boolean) {
    const filterForUpdate = Object.assign({}, garbageFilter);
    if (!Array.isArray(filterForUpdate.values)) return;
    const filterValuesForUpdate = filterForUpdate.values?.slice() ?? [];

    if (checked) {
      const valueIndex = filterForUpdate.values?.findIndex(
        (v) => v.value === "True"
      );
      if (typeof valueIndex === "number") {
        filterValuesForUpdate.splice(valueIndex, 1);
      }
    } else {
      filterValuesForUpdate.push({ label: "True", value: "True" });
    }
    filterForUpdate.values = filterValuesForUpdate;
    updateDimension(0, "filters", garbageFilterIndex, filterForUpdate);
  }

  return (
    <>
      {seasonFilterIndex !== -1 && (
        <>
          <Typography
            fontWeight={400}
            fontSize={12}
            textTransform="uppercase"
            mt={1}
          >
            Season
          </Typography>
          {seasonFilterOption?.values
            .slice()
            .sort((a, b) => (a.label < b.label ? 1 : -1))
            .map((v) => {
              if (!Array.isArray(seasonFilter?.values)) return null;
              const checked =
                seasonFilter?.values &&
                seasonFilter.values.some((v2) => v.value === v2.value);
              return (
                <StyledCheckbox
                  label={v.label}
                  value={v.value}
                  checked={checked}
                  key={`seasonCheckbox:${v.label}=${checked}`}
                  onChange={(...args) => checkBoxChange(...args, "season")}
                />
              );
            })}
        </>
      )}
      {seasonTypeFilterIndex !== -1 && (
        <>
          <Typography
            fontWeight={400}
            fontSize={12}
            textTransform="uppercase"
            mt={1}
          >
            Season Type
          </Typography>
          {seasonTypeFilterOption?.values
            .slice()
            .sort((a, b) => (a.label < b.label ? 1 : -1))
            .map((v) => {
              if (!Array.isArray(seasonTypeFilter?.values)) return null;
              const checked =
                seasonTypeFilter?.values &&
                seasonTypeFilter.values.some((v2) => v.value === v2.value);
              return (
                <StyledCheckbox
                  label={v.label}
                  value={v.value}
                  checked={checked}
                  key={`seasonTypeCheckbox:${v.label}=${checked}`}
                  onChange={(...args) => checkBoxChange(...args, "seasonType")}
                />
              );
            })}
        </>
      )}
      {garbageFilterIndex !== -1 && (
        <>
          <Typography
            fontWeight={400}
            fontSize={12}
            textTransform="uppercase"
            mt={1}
          >
            Remove garbage time
          </Typography>
          <FormControlLabel
            control={<Switch className="FormSwitch" />}
            checked={isGarbageFiltered}
            onChange={toggleChange}
            label=""
          />
        </>
      )}
    </>
  );
}
