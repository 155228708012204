// Imports
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Breakpoint,
} from "@mui/material";
import PropTypes from "prop-types";

// Stylesheet
import "./simpleModal.scss";

interface SimpleModalProps {
  isOpen: boolean;
  handleClose: (event: object, reason: string) => void;
  children?: React.ReactNode;
  actions?: React.ReactNode;
  title?: string
  maxWidth?: false | Breakpoint
}

export const SimpleModal = ({
  isOpen,
  handleClose,
  actions,
  children,
  title,
  maxWidth,
}: SimpleModalProps) => {
  return (
    <div className="simple-modal--container">
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="simple-modal--modal"
        maxWidth={maxWidth}
      >
        {/* NOTE: Close Button */}
        <DialogActions className="simple-modal--action-bar">
          <Typography variant="h4" className="simple-modal--title">{title}</Typography>
          <Button
            onClick={(event) => handleClose(event, "clickClose")}
            className="simple-modal--button"
            tabIndex={-1}
          >
            <FontAwesomeIcon
              icon={solid("xmark")}
              className="simple-modal--close-icon"
            />
          </Button>
        </DialogActions>

        {/* NOTE: Content */}
        <DialogContent className="simple-modal--content">
          {/* NOTE: Allows for dynamic content. */}
          {children}
        </DialogContent>
        {actions &&
          <DialogActions>
            {actions}
          </DialogActions>
        }
      </Dialog>
    </div>
  );
};

SimpleModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};
