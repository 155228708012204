// Imports
import { Radio, FormControlLabel, Typography } from "@mui/material";

// Stylesheet
import "./styledRadioButtons.scss";

interface StyledRadioButtonProps {
  label: string;
  value: string;
  textSize?: "sm" | "lg";
  disabled?: boolean;
  selected?: boolean;
}

export const StyledRadioButton = ({
  label,
  value,
  textSize,
  disabled,
  selected
}: StyledRadioButtonProps) => {
  return (
    <FormControlLabel
      className={`RadioControl${selected ? " selected" : disabled ? " disabled" : ""}`}
      value={value}
      control={<Radio className="radio-buttons" disabled={disabled} />}
      label={
        <Typography variant={textSize === "sm" ? "body2" : "body1"}>
          {label}
        </Typography>
      }
    />
  );
};
