import "./queryBuilder.scss";
import { useState, ChangeEvent, SyntheticEvent, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Tabs,
  Tab,
  ListItem,
  Grid,
} from "@mui/material";
import { Download, Edit, Save } from "@mui/icons-material";
import { useExplorerStore } from "../../../data/ExplorerStore";
import { SimpleModal } from "../../Elements/SimpleModal/SimpleModal";
import TabContent from "../../Elements/TabContent";
import QueryForm from "./QueryForm";
import MetricGroupForm from "./MetricGroupForm";

export default function QueryBuilder() {
  const [openDialog, setOpenDialog] = useState<
    "queryBuilder" | "savedQueries" | "saveQuery" | null
  >(null);
  const [currentTab, setCurrentTab] = useState<string>("query");

  const metricGroupsLength = useExplorerStore(
    ({ metricGroups }) => metricGroups.length
  );
  const pseudoMetricGroupArray = Array.from(
    { length: metricGroupsLength },
    (x, i) => i
  );
  const addMetricGroup = useExplorerStore(({ addMetricGroup }) => () => {
    addMetricGroup();
    setCurrentTab(`metricGroup${metricGroupsLength}`);
  });
  const [queryName, queryId] = useExplorerStore(({ queryName, queryId }) => [queryName, queryId]);
  const [saveName, setSaveName] = useState<string>(queryName);
  const savedQueries = useExplorerStore(({ savedQueries }) => savedQueries);
  const saveNameExists = savedQueries.some((sq) => sq.NAME === saveName);
  const loadSavedQuery = useExplorerStore(
    ({ loadSavedQuery }) =>
      (i: number) => {
        loadSavedQuery(i);
        setCurrentTab("query");
        setOpenDialog("queryBuilder");
      }
  );
  const saveQuery = useExplorerStore(({ saveQuery }) => () => {
    setOpenDialog(null);
    saveQuery(saveName);
  });
  const execQuery = useExplorerStore(({ execQuery }) => () => {
    setOpenDialog(null);
    execQuery();
  });

  useEffect(() => {
    setSaveName(queryName);
  }, [queryName]);

  useEffect(() => {
    if (queryId) {
      window.history.pushState(null, "", `#${queryId}`)
    } else {
      window.history.pushState(null, "", window.location.pathname)
    }
  }, [queryId])

  const queryBuilderActions = (
    <>
      <Button
        color="secondary"
        variant="outlined"
        onClick={() => setOpenDialog("savedQueries")}
      >
        Load
      </Button>
      <Button variant="outlined" onClick={execQuery}>
        Run
      </Button>
    </>
  );

  const saveQueryAction = (
    <Button variant="outlined" disabled={!saveName} onClick={saveQuery}>
      Save
    </Button>
  );

  const changeSaveName = (e: ChangeEvent<HTMLInputElement>) => {
    setSaveName(e.target.value);
  };

  return (
    <>
      <Button
        color="secondary"
        variant="outlined"
        onClick={() => setOpenDialog("queryBuilder")}
        sx={{ display: "flex", flexDirection: "column", py: 0 }}
      >
        <Edit fontSize="small" />
        <Typography variant="caption" sx={{ fontSize: "10px" }}>
          Edit
        </Typography>
      </Button>
      <Button
        color="secondary"
        variant="outlined"
        onClick={() => setOpenDialog("saveQuery")}
        sx={{ display: "flex", flexDirection: "column", py: 0 }}
      >
        <Save fontSize="small" />
        <Typography variant="caption" sx={{ fontSize: "10px" }}>
          Save
        </Typography>
      </Button>
      <Button
        color="secondary"
        variant="outlined"
        onClick={() => setOpenDialog("savedQueries")}
        sx={{ display: "flex", flexDirection: "column", py: 0 }}
      >
        <Download fontSize="small" />
        <Typography variant="caption" sx={{ fontSize: "10px" }}>
          Load
        </Typography>
      </Button>
      <SimpleModal
        isOpen={openDialog === "queryBuilder"}
        handleClose={() => setOpenDialog(null)}
        actions={queryBuilderActions}
        title="Query Builder"
      >
        <>
          <Tabs
            value={currentTab}
            onChange={(e: SyntheticEvent, newValue: string) =>
              setCurrentTab(newValue)
            }
            variant="fullWidth"
            className="query-builder--tabs-container"
          >
            <Tab label="Query" value="query" />
            {pseudoMetricGroupArray.map(
              (x, i) =>
                i !== 0 && (
                  <Tab
                    label={`Metric Group${i > 1 ? ` ${i}` : ""}`}
                    value={`metricGroup${i}`}
                    key={`metricGroup${i}--tab`}
                  />
                )
            )}
            <Tab label="+ Metric Group" onClick={addMetricGroup} />
          </Tabs>
          <Box className="query-builder--form-area">
            <TabContent tabName="query" currentTab={currentTab}>
              <QueryForm metricGroupIndex={0} />
            </TabContent>
            {pseudoMetricGroupArray.map(
              (x, i) =>
                i !== 0 && (
                  <TabContent
                    tabName={`metricGroup${i}`}
                    currentTab={currentTab}
                    key={`metricGroup${i}--panel`}
                  >
                    <MetricGroupForm i={i} />
                  </TabContent>
                )
            )}
          </Box>
        </>
      </SimpleModal>

      <SimpleModal
        isOpen={openDialog === "savedQueries"}
        handleClose={() => setOpenDialog("queryBuilder")}
        title="Saved queries"
      >
        <Grid
          container
          direction="column"
          wrap="wrap"
          columns={2}
          maxHeight={"calc(100vh - 164px)"}
        >
          {savedQueries.map((sq, i) => {
            if (!sq.NAME) return null;
            return (
              <ListItem
                key={sq.ID}
                sx={{ width: "fit-content", cursor: "pointer" }}
                onClick={() => loadSavedQuery(i)}
                className="saved-query"
              >
                {sq.NAME}
              </ListItem>
            );
          })}
        </Grid>
      </SimpleModal>

      <SimpleModal
        isOpen={openDialog === "saveQuery"}
        handleClose={() => setOpenDialog(null)}
        title="Save query"
        actions={saveQueryAction}
      >
        <>
          <Typography mb={1}>Save query as:</Typography>
          <TextField
            value={saveName}
            placeholder="Enter query name"
            onChange={changeSaveName}
          />
          {!!saveName && saveNameExists && (
            <Typography color="error" mt={3}>
              Warning, a query is already saved by this name. Do you want to
              overwrite this query?
            </Typography>
          )}
        </>
      </SimpleModal>
    </>
  );
}
