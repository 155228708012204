import { Container, List, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { GameWithID } from "../../models/Game";
import { getGameLog } from "../../util/Requests";
import GamesSkeleton from "../Elements/GameDisplay/GamesSkeleton";
import PastGame from "../Elements/GameDisplay/PastGame";

export default function GameLog() {
  const [games, setGames] = useState<GameWithID[]>([])

  useEffect(() => {
    getGameLog()
      .then((response) => {
        setGames(response.data.map((gameTuple) => ({
          id: gameTuple[0],
          date: gameTuple[1],
          opponentInfo: gameTuple[2],
        })))
        console.log("game log response: ", response)
      })
      .catch((err) => {
        console.log("error fetching game log: ", err)
      })
  }, [])

  return (
    <Container maxWidth='xl'>
      <Typography variant='h2' my={4}>Season game log</Typography>
      <List>
        {games.length === 0 ?
          <GamesSkeleton num={10} />
          : games.map((game) => <PastGame game={game} key={game.id}/>)
        }
      </List>
    </Container>
  )
}
